@import "shared/styles/core";

.progressBar {
  position: absolute;
  top: -$default-border-width;
  right: 0;
  left: 0;
  width: 100%;
  height: $default-border-width;
  background: transparent;
  overflow: hidden;
  pointer-events: none;
  transform: scaleY(0);
  transform-origin: 0 100%;
  transition: transform 0.3s ease-in, background 0.2s ease-in;

  &.active {
    transform: scaleY(1);
    background: var(--color-mini-progress-bg);
  }

  &.alignBottom {
    top: auto;
    bottom: 0;
  }
}

.progress {
  display: block;
  background: var(--primary-p500);
  height: 100%;
  width: 0;
  transition: width 0.24s ease-in;
}

@include media-breakpoint-up(lg) {
  .progressBar {
    display: none;
  }
}
