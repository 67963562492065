.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  @include heading-base();

  margin: 0;
}

b,
strong {
  font-family: var(--font-family-body-bold);
}

p {
  margin: 0;
  line-height: 1.6;
}

h1 {
  font-size: var(--font-size-h1-sm);
}

h2 {
  font-size: var(--font-size-h2-sm);
}

h3 {
  font-size: var(--font-size-h3-sm);
}

h4 {
  font-size: var(--font-size-h4-sm);
}

h5 {
  font-size: var(--font-size-h5-sm);
}

h6 {
  font-size: var(--font-size-h6-sm);
}

@include media-breakpoint-up(lg) {
  p {
    font-size: var(--font-size-body-desktop);
  }

  h1 {
    font-size: var(--font-size-h1);
  }

  h2 {
    font-size: var(--font-size-h2);
  }

  h3 {
    font-size: var(--font-size-h3);
  }

  h4 {
    font-size: var(--font-size-h4);
  }

  h5 {
    font-size: var(--font-size-h5);
  }

  h6 {
    font-size: var(--font-size-h6);
  }
}
