@import "shared/styles/core";

.navigationWrapper {
  position: fixed;
  right: 0;
  bottom: -1px;
  left: 0;
  z-index: $top-layer;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);

  &.hide {
    visibility: hidden;
    pointer-events: none;
  }
}

.bottomNavigation {
  position: relative;
  display: grid;
  align-items: center;
  padding: ($spacing * 3) ($spacing * 3) ($spacing * 7);
  background-color: var(--bottom-nav-background);
  box-shadow: 0 -1px 0 rgb(0 0 0 / 8%);
  backdrop-filter: blur(40px);
  grid-gap: ($spacing * 4);
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  z-index: $top-layer;

  &.mobile {
    padding-bottom: ($spacing * 4);
  }
}

.link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  line-height: 1;
  color: var(--bottom-nav-inactive);
  text-decoration: none;
  width: 100%;

  &.active {
    color: var(--bottom-nav-active);

    .label {
      color: var(--bottom-nav-active);
    }
  }
}

.label {
  white-space: nowrap;
  font-family: var(--font-family-bottom-nav);
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  color: var(--bottom-nav-inactive);
  margin-top: ($spacing * 0.5);
}

@include media-breakpoint-up(lg) {
  .navigationWrapper {
    display: none;
  }
}
