@import "shared/styles/core";

.image {
  position: relative;
  display: block;
  position: relative;
  flex: 0 0 $artwork-size;
  max-width: $artwork-size;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  font-size: 0;
  overflow: hidden;
}
