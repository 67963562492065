@import "shared/styles/core";

$additional-controls-height: $bottom-player-timeline-height +
  $bottom-player-trigger-height;

.wrapper {
  position: absolute;
  top: -$bottom-bars-height;
  left: 0;
  right: 0;
  color: var(--content-secondary);
  z-index: $layer-9;
  font-family: var(--font-family-body);
  background: rgb(0 0 0 / 80%);
  backdrop-filter: blur(40px);
  margin: 0 0 $bottom-nav-height;
  transition: transform 0.4s ease-in;

  &.showCloseControl {
    transform: translateY(-$bottom-player-trigger-height);
  }

  &.showFullControls {
    transform: translateY(-(#{$additional-controls-height}));
  }

  &.closed {
    display: none;
  }
}

.playerBar {
  position: relative;
  display: flex;
  width: 100%;
  height: $bottom-player-height;
  color: var(--core-white);
  padding-bottom: ($spacing * 0.5);

  .timeline {
    bottom: 0;
    top: unset;
  }
}

.additionalControls {
  height: $additional-controls-height;
  padding: 0 ($spacing * 2);
}

.imageWrapper {
  flex: 0 0 $bottom-player-height;
  margin: 0 ($spacing * 3) 0 0;
  padding: $spacing;
  overflow: hidden;

  .artwork {
    border-radius: $spacing;
  }
}

.playerMeta {
  display: flex;
  flex: 1 1 100%;
  overflow: hidden;
}

.metaWrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  border: none;
  background: none;
  color: var(--core-white);
  padding: 0;
  text-align: left;
}

.toggler,
.controls {
  display: flex;
  flex: 0 0 $big-icon-button-size;
  align-items: center;
  justify-content: center;
  max-width: $big-icon-button-size;
  color: inherit;
}

.closeTrigger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  height: ($spacing * 5);
  font-family: var(--font-family-secondary);
  font-size: var(--font-size-button);
  font-style: var(--font-weight-secondary);
  line-height: 1;
  color: var(--core-white);
  cursor: pointer;
  padding-top: ($spacing * 3);
}

.closeLabel {
  position: relative;

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    left: -($spacing * 5);
    content: "";
    width: $spacing * 3;
    height: ($spacing * 0.5);
    background: var(--core-white);
  }

  &::before {
    transform: translate(0, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(0, -50%) rotate(-45deg);
  }
}

.spinnerButton {
  color: var(--core-white);

  & > svg {
    display: block;
    width: 90%;
    height: auto;
  }
}

.toggler {
  svg {
    transition: transform 0.2s ease-in;
  }

  &.active {
    svg {
      transform: rotateX(180deg);
    }
  }
}

.playButton {
  color: inherit;
}

.closeWrapper {
  margin: ($spacing * 0.5) 0 0;
  height: $bottom-player-trigger-height;
  text-align: center;
  font-style: var(--font-style-hx);
  position: relative;
}
