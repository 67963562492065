@import "shared/styles/core";

.button {
  color: var(--core-white);

  &.large {
    color: var(--content-primary);
  }
}

@include media-breakpoint-up(lg) {
  .button {
    color: var(--foundation-on-primary);
  }
}
