@import "shared/styles/core";

$mixer-size: $spacing * 3;
$bar-size: $spacing * 12;

.mixerWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  color: var(--color-player-controls);

  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: $spacing;
    background-size: $spacing;
    content: "";
    display: block;
    background-color: rgba(255, 255, 255, 0.24);
    transform: translateY(-50%);
    border-radius: ($spacing * 25);
  }

  &.standard,
  &.radio {
    grid-column: 2/3;
  }

  &.fullscreen {
    height: $bar-size;
    grid-column: 1/3;
    &::before {
      height: 5px;
      background: var(--gray-g500);
      border-radius: ($spacing * 25);
    }

    &.pushToBottom {
      margin: $spacing 0 0;

      &::before {
        margin: ($spacing * 1.5) 0 0;
      }
    }
  }
}

.mixerTooltip {
  position: absolute;
  bottom: 0;
  left: 50%;
  color: inherit;
  font-size: ($spacing * 2.5);
  font-style: normal;
  padding: ($spacing) ($spacing * 2);
  background: var(--primary-p500);
  opacity: 0;
  transform: translate(-50%, 0);
  transition: transform 0.15s ease-in, opacity 0.15s ease-in;

  &.standard {
    bottom: ($spacing * 4)
  }

  &.active {
    opacity: 1;
    transform: translate(-50%, -18px);
  }
}

.mixerProgress {
  height: $spacing;
  width: 100%;
  align-self: center;
  border-radius: ($spacing * 25);

  &.fullscreen {
    height: 5px;
    border-radius: ($spacing * 25);
  }
}

.mixerTrack {
  height: ($spacing * 3);
  display: flex;
  width: 100%;
  background: transparent;
  &.fullscreen {
    &.pushToBottom {
      margin: ($spacing * 3) 0 0;
    }
  }
}

.mixerThumb {
  height: ($spacing * 3);
  width: ($spacing * 3);
  border: 0;
  box-shadow: none;
  border-radius: 100%;
  background: var(--color-player-controls);

  &:focus {
    box-shadow: none;
    outline: 0;
  }

  &.fullscreen {
    background: var(--core-white);
    height: ($spacing * 3.5);
    width: ($spacing * 3.5);
    border: 3px solid var(--core-black);
  }

  &.radio {
    display: none;
  }
}

.title,
.subTitle {
  display: block;
  margin: 0;
  width: 100%;
  white-space: nowrap;
  font-size: var(--font-size-small);
  line-height: 1.33;

  &.marqueed {
    animation: marquee 12s linear infinite;
  }
}

.title {
  margin: 0 0 ($spacing * 0.5);
  font-family: var(--font-family-body-bold);
}

.timeline {
  display: grid;
  width: 100%;
  padding: 0;
  grid-template-columns: auto 1fr auto;

  &.standard,
  &.radio {
    align-items: center;
    grid-gap: ($spacing * 2);
  }

  &.fullscreen {
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
    height: auto;
  }
}

.elapsed,
.duration {
  top: ($spacing * 1.5);
  color: var(--core-white);
  font-size: var(--font-size-small);

  &.fullscreen {
    color: var(--content-primary);
  }
}

.elapsed {
  grid-column: 1/2;
  grid-row: 1/2;
  &.fullscreen {
    grid-row: 2/3;
    justify-self: flex-start;
  }
}

.duration {
  grid-column: 3/4;
  grid-row: 1/2;
  &.fullscreen {
    grid-column: 2/3;
    grid-row: 2/3;
    justify-self: flex-end;
  }
}

// White Theme for bottom player
.whiteTheme {
  &.mixerWrapper {
    color: var(--core-white);

    &::before {
      background-image: linear-gradient(
        90deg,
        var(--core-white),
        var(--core-white) 45%,
        transparent 45%,
        transparent 100%
      );
    }
  }

  .mixerTooltip {
    background: var(--core-black);

    &.active {
      transform: translate(-50%, -16px);
    }
  }

  .mixerThumb {
    background: var(--core-white);
  }
}

.notActiveTimeline {
  height: $spacing;
  background-color: rgba(255, 255, 255, 0.24);
  border-radius: ($spacing * 25);
  width: 100%;

  .progress {
    display: block;
    background: var(--color-negative);
    border-radius: ($spacing * 25);
    height: 100%;
    width: auto;
  }
}

@include media-breakpoint-up(lg) {
  .elapsed,
  .duration {
    color: var(--foundation-on-primary);
  }
}
