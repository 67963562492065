@import "shared/styles/core";

.visually-p {
  line-height: 1.6;
}

.visually-small {
  font-size: var(--font-size-small);
  line-height: 1.33;
  letter-spacing: -0.02em;
}

.visually-medium {
  font-size: var(--font-size-medium);
  line-height: 1.42;
  letter-spacing: -0.015em;
}

.visually-large {
  font-size: var(--font-size-large-sm);
  line-height: 1.55;
  letter-spacing: -0.02em;
}

.hero {
  font-family: var(--font-family-heading);
  font-style: var(--font-style-hx);
  font-size: var(--font-size-hero-sm);
  font-weight: var(--font-weight-sections-heading);
  line-height: 1.14;
}

.visually-h1,
.visually-h2,
.visually-h3,
.visually-h4,
.visually-h5,
.visually-h6 {
  @include heading-base();
}

.visually-h1 {
  font-size: var(--font-size-h1-sm);
  letter-spacing: var(--heading-letter-spacing);
}

.visually-h2 {
  font-size: var(--font-size-h2-sm);
  letter-spacing: var(--heading-letter-spacing);
}

.visually-h3 {
  font-size: var(--font-size-h3-sm);
}

.visually-h4 {
  font-size: var(--font-size-h4-sm);
  letter-spacing: var(--heading-letter-spacing);
}

.visually-h5 {
  font-size: var(--font-size-h5-sm);
  letter-spacing: var(--heading-letter-spacing);
}

.visually-h6 {
  font-size: var(--font-size-h6-sm);
  letter-spacing: var(--h6-letter-spacing);
}

.visually-secondary-font {
  @include secondary-heading-base();
}

.section-heading {
  @include secondary-heading-base();

  text-transform: uppercase;
}

@include media-breakpoint-up(lg) {
  .visually-p {
    font-size: var(--font-size-body-desktop);
  }

  .visually-large {
    font-size: var(--font-size-large);
  }

  .hero {
    font-family: var(--font-family-hero-desktop);
    font-size: var(--font-size-hero);
    font-weight: var(--font-weight-hero-desktop);
    text-transform: uppercase;
  }

  .visually-h1 {
    font-size: var(--font-size-h1);
  }

  .visually-h2 {
    font-size: var(--font-size-h2);
  }

  .visually-h3 {
    font-size: var(--font-size-h3);
  }

  .visually-h4 {
    font-size: var(--font-size-h4);
  }

  .visually-h5 {
    font-size: var(--font-size-h5);
  }

  .visually-h6 {
    font-size: var(--font-size-h6);
  }
}
