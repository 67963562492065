.hide-on-mobile,
.show-on-xl {
  display: none;
}

@include media-breakpoint-down(sm) {
  .tp-iframe-wrapper.tp-active {
    margin: 0 !important;
    height: calc(var(--vh, 1vh) * 100) !important;

    iframe {
      min-height: 100% !important;
      overflow: scroll;
    }
  }
}

@include media-breakpoint-up(lg) {
  .hide-on-mobile {
    display: block;
  }

  .hide-on-desktop {
    display: none;
  }
}

@include media-breakpoint-up(xl) {
  .show-on-xl {
    display: block;
  }

  .hide-on-xl {
    display: none;
  }
}

#qc-cmp2-ui,
#qc-cmp2-ui::before,
#qc-cmp2-ui::after {
  background: var(--surface-secondary);
}

#qc-cmp2-ui button[mode="primary"],
#qc-cmp2-ui button[mode="secondary"],
#qc-cmp2-ui button[mode="link"] {
  font-family: var(--font-family-secondary);
  font-style: var(--font-style-hx);
  font-weight: var(--font-weight-sections-heading);
  height: 48px;
  font-size: var(--font-size-button);
  letter-spacing: .04em;
  text-transform: uppercase;
  border: 2px solid var(--core-black);
  outline: none;
  box-shadow: 2px 2px 0 var(--core-black);
  cursor: pointer;
  transition: color .2s ease-in, background .2s ease-in;
}

#qc-cmp2-ui button[mode="primary"] {
  color: var(--color-button-text-primary);
  background: var(--primary-p500);
}

#qc-cmp2-ui button[mode="secondary"] {
  color: var(--content-primary);
  background-color: var(--surface-secondary);
  border-color: var(--core-black);
}

#qc-cmp2-ui button[mode="link"] {
  background-color: transparent;
  border: none;
  box-shadow: none;
  height: auto;
  color: var(--content-primary);
}


#qc-cmp2-ui h2 {
  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-sections-heading);
  font-style: var(--font-style-hx);
  line-height: 1.2;
  font-size: var(--font-size-h2);
}

#qc-cmp2-ui p {
  font-size: var(--font-size-medium);
  line-height: 1.42;
  letter-spacing: -.015em;
  font-family: var(--font-family-body);
}

#qc-cmp2-ui h2,
#qc-cmp2-ui p {
  color: var(--content-primary);
}
.qc-cmp2-summary-info,
.qc-cmp2-summary-info div {
  color: var(--content-primary) !important;
}
