@import "shared/styles/core";

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  font-family: var(--font-family-body-bold);
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  display: flex;
  gap: $spacing;
}

.icon {
  transform: rotate(180deg);
}

.menu {
  position: absolute;
  background-color: var(--surface-tertiary);
  border: 1px solid var(--gray-g100);
  padding: ($spacing * 2);
  border-radius: ($spacing * 2.5);
  display: flex;
  filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.08));
  top: -($spacing * 17);

  &::after {
    position: absolute;
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    border-bottom: 1px solid var(--gray-g100);
    border-right: 1px solid var(--gray-g100);
    background: var(--surface-tertiary);
    transform: rotate(45deg);
    bottom: -6px;
    left: 48%;
  }
}

.rate {
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  font-size: var(--font-size-medium);
  line-height: ($spacing * 5);
  padding: ($spacing * 2.5) ($spacing * 2);
  border-radius: ($spacing * 1.5);
  min-width: ($spacing * 12);
  color: var(--content-primary);
  &:not(:last-child) {
    margin-right: 1px;
  }

  &:hover,
  &.active {
    background-color: var(--gray-g50);
  }
}
