:root {
  --font-family-body: "HelveticaNowText-Regular", helvetica, sans-serif;
  --font-family-body-bold: "HelveticaNowText-Bold", helvetica, sans-serif;
  --font-size-body: 16px;
  --font-size-body-desktop: 18px;
  --color-navigation: #000;
  --color-gray: #d2d2d2;
  --color-dark-gray: #8a8a8a;
  --color-darkest-gray: #7a7a7a;
  --core-white: #fff;
  --core-black: #000;
  --color-positive: #0da100;
  --color-warning: #e25c1c;
  --color-help: #853aff;
  --color-information: #1fa1ff;
  --color-negative: #d42626;
  --color-twitch: #9146ff;
}

html {
  height: 100%;
}

body {
  min-height: 100vh;
  font-family: var(--font-family-body);
  font-weight: 500;
  font-size: var(--font-size-body);
  box-sizing: border-box;
  background: var(--surface-secondary);
  color: var(--content-primary);
  overflow-x: hidden;

  * {
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;

    &::before,
    &::after {
      box-sizing: inherit;
    }
  }

  &.isMenuOpen {
    overflow: hidden;
  }

  @media screen and (min-aspect-ratio: 13/9) and (max-width: 1024px) and (hover: none) and (pointer: coarse) {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    &::before {
      content: "Obróc telefon aby, używać aplikacji.";
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10000;
      background: var(--surface-secondary);
      color: var(--content-primary);
      font-family: inherit;
    }
  }
}

.audioPlayerWrapper {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: -1;
}

#__next {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

button {
  appearance: none;
}

input {
  appearance: none;
}

// Override JWPlayer styles
.jwplayer {
  .jw-wrapper {
    .jw-controls {
      display: none;
    }
  }
}

.video-fullscreen {
  .jwplayer {
    .jw-wrapper {
      .jw-controls {
        display: block;

        .jw-controlbar {
          display: block;
        }
      }
    }
  }
}

.tp-modal button.tp-close {
  border-radius: 0;
  top: 24px;
  right: 24px;
  border: 2px solid var(--core-black);
  box-shadow: 2px 2px 0 var(--core-black);
  color: var(--core-black);
}

@include media-breakpoint-up(lg) {
  body {
    font-size: var(--font-size-body-desktop);
    font-weight: 500;
  }
}
