@import "shared/styles/core";

.iconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ($spacing * 2);
  background: transparent;
  border: 2px solid transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.24s ease-in, background 0.24s ease-in;
}

.primary {
  @include border-with-shadow;

  color: var(--content-primary);
  background: var(--surface-tertiary);

  &:hover {
    color: var(--link-on-surface);
  }

  &:not(:disabled) {
    &:active {
      @include border-with-shadow-pressed;
    }
  }

  &.active {
    background: var(--primary-p500);
    color: var(--foundation-on-primary);

    & > svg {
      * {
        fill: var(--foundation-on-primary);
      }
    }
  }

  &.big {
    width: $big-icon-button-size;
    height: $big-icon-button-size;
  }
}

.dark {
  color: var(--core-white);
  background-color: var(--core-black);

  &:hover {
    background-color: var(--link-on-surface);
  }

  &:active {
    background: var(--link-on-surface);
  }
}

.round {
  border: none;
  padding: 0;
  background: var(--gray-g50);
  color: var(--content-primary);
  border-radius: 50%;
  width: ($spacing * 9);
  height: ($spacing * 9);

  &:hover {
    background: var(--gray-g100);
    transition: background 0.2s ease-in;
  }

  &:active,
  &.active {
    color: var(--primary-p500);
    background: var(--surface-primary);
    transition: all 0.2s ease-in;

    &.lightSport {
      background-color: var(--follow-author-button-background-active);
    }

    &:hover {
      color: var(--primary-p500);
      background: var(--gray-g100);

      &.lightSport {
        background-color: var(--follow-author-button-background-active);
      }
    }
  }
}

.square {
  border: 1px solid var(--gray-g25);
  border-radius: ($spacing * 2);
  background: var(--surface-secondary);
  color: var(--content-primary);

  &:hover,
  &:active,
  &.active {
    color: var(--link-on-surface);
    transition: color 0.2s ease-in;
  }

  &.big {
    min-width: $big-icon-button-size;
  }

  &.small {
    min-width: $small-icon-button-size;
  }
}

.big {
  width: $big-icon-button-size;
  height: $big-icon-button-size;
}

.medium {
  width: $medium-icon-button-size;
  height: $medium-icon-button-size;
}

.small {
  width: $small-icon-button-size;
  height: $small-icon-button-size;
  padding: 0;
}

.withLabel {
  width: auto;
  padding: ($spacing * 2) ($spacing * 3);
  font-size: var(--font-size-button);
  gap: $spacing;
  border: 1px solid var(--gray-g400);
  border-radius: ($spacing * 2);
  background: var(--surface-tertiary);
  color: var(--content-primary);
  font-family: var(--font-family-body-bold);

  &:hover {
    background: var(--gray-g50);
  }

  &:active,
  &.active {
    border-color: var(--surface-tertiary);
    color: var(--link-on-surface);
    background: var(--surface-tertiary);

    &.lightSport {
      background-color: var(--follow-author-button-background-active);
      border-color: var(--follow-author-button-background-active);
      color: var(--follow-author-button-color-active);
    }
  }
}
