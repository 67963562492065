@import "shared/styles/core";

.wrapper {
  position: fixed;
  bottom: 0;
  height: ($spacing * 17);
  width: 100%;
  background-color: black;
  display: none;
  padding: ($spacing * 2) ($spacing * 6) ($spacing * 4.5);
  color: var(--core-white);
  z-index: $top-layer;

  .content {
    z-index: $layer-1;
    display: flex;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
  }
}

.backgroundImage {
  opacity: 0.6;
}

.mediaType {
  display: flex;
  flex-direction: column;
}

.button {
  background: none;
  border: none;
  color: var(--core-white);
  opacity: 0.48;
  display: flex;
  align-items: center;
  gap: $spacing;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &.active {
    opacity: 1;
  }

  &.hide {
    display: none;
  }
}

.playButton {
  background-color: var(--core-white);
  color: var(--core-black);

  &:hover {
    background-color: #e5eaec;
  }
}

.meta {
  display: flex;
  flex-direction: column;
  margin-left: ($spacing * 2);
  width: 100%;
  overflow: hidden;
}

.artwork {
  border-radius: 4px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.32);
  flex: 0 0 ($spacing * 10);
  max-width: ($spacing * 10);
  height: ($spacing * 10);
  margin-left: ($spacing * 4);
}

.controls {
  display: flex;
  margin-left: ($spacing * 8);
}

.title {
  display: block;
  margin: 0;
  width: 100%;
  white-space: nowrap;
  font-size: var(--font-size-medium);
  line-height: ($spacing * 5);
  letter-spacing: -0.015em;

  &.bold {
    font-family: var(--font-family-body-bold);
  }

  &.marqueed {
    animation: marquee 12s linear infinite;
  }
}

.timelineContainer {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ($spacing * 6);
}

.timeline {
  min-width: 230px;
  max-width: 384px;
  width: 100%;
}

.rate {
  color: var(--core-white);
  font-size: var(--font-size-medium);
}

.close {
  border: none;
  padding: 0;
  height: ($spacing * 6);
  width: ($spacing * 6);
  background: rgba(255, 255, 255, 0.24);
  color: var(--core-white);
  border-radius: 50%;
  margin-top: ($spacing * 2);
  cursor: pointer;

  &:hover,
  &:active {
    background: rgba(255, 255, 255, 0.32);
    color: var(--core-white);
    transition: background 0.2s ease-in;
  }
}

.volume {
  display: flex;
  align-items: center;
  min-width: 140px;
  margin: 0 ($spacing * 16) 0 ($spacing * 6);
  z-index: $layer-1;
}

.volumeIcon {
  min-width: ($spacing * 5);
  color: rgba(255, 255, 255, 0.56);

  &.max {
    margin-left: $spacing;
  }
}

.volumeMixer {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 $spacing;
  border: 0;
  pointer-events: all;
  user-select: none;
  z-index: -1;
}

.link {
  text-decoration: none;
  color: inherit;
  max-width: 232px;
  min-width: 115px;
  width: 100%;
}

.radioTimeline {
  margin: 0 70px 0 44px;
  min-width: 370px;
}

@include media-breakpoint-up(lg) {
  .wrapper {
    display: flex;
  }
}

@include media-breakpoint-up(xl) {
  .radioTimeline {
    margin-left: 0;
  }
}
